<template>
  <div class="card" style="background-color: #161616;padding: -1px;">
    <LazyVideoPlayer
      :autoplay="this.autoplay"
      :videoId='this.videoId'
      :width="this.width"
      :height="this.height"
      :title="this.title"
      :videoUrl="`https://www.youtube.com/embed/${this.videoId}`">
    </LazyVideoPlayer>
    <div style="padding: 20px">
      <div  :class="textAlign">
        <h4 class="tulum-party-subtitle" v-if="this.title" style="font-size: 1.2rem">{{ this.title }}</h4>
        <p style="margin-top: -10px" v-if="this.caption">{{ this.caption }}</p>
        <div v-html="this.content" style="margin-top: -12px">{{ this.content }}</div>
        <br>
        <div class="text-right" style="color: black !important;margin-bottom: -10px;"
             v-if="this.mtv">
          <p style="font-size:.8rem;font-weight: bold"
          >TULU<strong
            style="font-size: .8rem;color: #00f2c3;border: 1px solid aquamarine;padding: 2px">MTV</strong><br>
          </p>
        </div>

      </div>
      <a  v-if="this.link && this.actionLabel"
          @click="clickLog" :href="`${this.link}`"
          class="btn btn-primary btn-sm text-center" style="margin-top: 15px">{{ this.actionLabel }}</a>

    </div>
  </div>
</template>
<script>
const LazyVideoPlayer = () => import('@/components/LazyVideoPlayer/LazyVideoPlayer');

export default {
  name: 'VideoCard',
  props: {
    title: {
      type: String,
      description: 'Card title',
    },
    align: {
      type: String,
      default: 'left',
      description: 'Text Alignment',
    },
    caption: {
      type: String,
      description: 'Card subtitle',
    },
    mtv: {
      type: Boolean,
      description: 'Show MTV Title ',
      default: false
    },
    content: {
      type: String,
      description: 'Card html',
    },
    width: {
      type: Number,
      default: 300
    },
    link: {
      type: String,
      description: 'Link on Action',
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    actionLabel: {
      type: String,
      description: 'Card subtitle',
      default: ''
    },
    videoId: {
      type: String,
      description: 'Youtube Video Id',
    },
    autoplay: {
      type: Boolean,
      description: 'Video Autoplay',
      default: false
    }
  },
  methods: {
    clickLog() {
      this.$rollbar.info(`VideoCard Click: ${this.title}`);
    },
  },
  computed: {
    textAlign() {
      return 'text-' + this.align;
    },
  },
  components: {LazyVideoPlayer}
};
</script>
